import { render, staticRenderFns } from "./ManageHotel.vue?vue&type=template&id=68a8ab2e&scoped=true"
import script from "./ManageHotel.vue?vue&type=script&lang=js"
export * from "./ManageHotel.vue?vue&type=script&lang=js"
import style0 from "./ManageHotel.vue?vue&type=style&index=0&id=68a8ab2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a8ab2e",
  null
  
)

export default component.exports